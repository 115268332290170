.common__section {
  background: linear-gradient(rgb(0, 13, 107, 0.6), rgb(0, 13, 107, 0.6)),
    url('../assets/all-images/drive.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0px;
  margin-bottom: 3rem;
}

@media only screen and (max-width: 768px) {
  .common__section {
    margin-bottom: 0 !important;
  }
}
